<template>
  <div class="TitleBox">
  <div class="GenerateBook">
    <h1 class="TitleIs">Your Book Title is.....</h1>
    <hr/>
    </div>
    <h1 class="Title">{{ word }}</h1> 
    <button @click="generateTitle"> New Title </button>
  </div>

</template>

<script>
import {starting, firstBlank, conjunction, secondBlank} from "@/dictionary.js"
import {ref} from "vue";

export default {
  props: {
    msg: String,
  },
  setup() {
    let word = ref("");
    const VOWELS = ["A", "E", "I", "O"];


    let getWord = (words) => {
      return words[Math.floor(Math.random()* words.length)];
    }
    
    let generateTitle = () => {
      let start = getWord(starting);
      let firstWord = getWord(firstBlank);
      let secondWord = getWord(secondBlank);
      let conjunctionWord = getWord(conjunction);
      let thirdWord = getWord(secondBlank);
      

      if(start=="A" && VOWELS.includes(firstWord.charAt(0)))
      {
        start ="An";
      }
      if(secondWord == thirdWord)
      {
        let secondWord = getWord(secondBlank);
      }

      word.value = `${start} ${firstWord} ${conjunctionWord} ${secondWord} and ${thirdWord}`;
    } 

    generateTitle();

    return {generateTitle, word};
  },
  
};



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @font-face {
  font-family: "Beau Rivage";
    src: local("Beau Rivage"),
    url(../fonts/BeauRivage-Regular.ttf) format("truetype");
  }

  .TitleIs{

    color:#68a19c;
  }

  .TitleBox{
    background-color:white;
     border: solid;
    border-radius: 15px;
    border-color: #68a19c;
    padding-bottom:10px;
    margin:20px;
    
  }

  hr{
    color:#68a19c;
    height: 10px;
    background-color:#68a19c;
  }

  .Title{
    font-family: "Beau Rivage", sans-serif;
    font-size: 50pt;
    color:#ad8d49;
  }

  button{
    background-color: white;
    
    font-family: sans-serif;
    font-weight: bold;
    color:#68a19c;
    padding: 12px 20px;
    font-size:28px;
    border: solid;
    border-radius: 15px;
    border-color: #68a19c;
    
    &:hover{
      background-color: #68a19c;
      color:white;
    }
  }

</style>
