
export const starting = ["The", "A", "My","", "Her", "His", "Our"];

export const firstBlank = ["Curse", "Marked", "Embrace", "Need", "Destiny", "Goddess", 
"City", "God", "Desire", "Defiance", "Halo", "Dragon", "Wizard", "Throne", 
"Child", "Half-Life", "Sunset", "Beginning", "Widow", "Savage", "Shifter", "Voyage", 
"Demon", "Space", "Way", "Garden", "Wedding", "Clash", "End", 
"Incubus", "Conquest","Eye", "Keeper", "Forgery","Angel", "Tree", 
"Final Life", "Road", "Ballad", "Bride", "Kingdom", "Crown", "Mother", "Touch", 
"Legend", "Rise", "Justice", "Night", "League", "Boy", "Girl", "Age", 
"House", "Victim", "Fate", "Mask", "Dance", "Midnight", "Wheel", "Fight"];

export const conjunction = ["of", "Between", "in the", "of the"];

export const secondBlank = ["Wolves", "Stardust", "Illusions", "Cinder", "Dusk", 
"Deception", "Greed", "Wrath",  "Pirates", "Wind", "Petals", "Sins", "Love", "Stories", 
"Swords", "Trebuchets","Magic", "Death", "Siege", "Paper", "Moonlight", "Blood", "Flesh", 
"Thorns", "North", "Smoke", "Lust", "Pride", "Envy", "Freedom", "String", "Salt", "Demons", 
"Diamond", "War", "Sage", "Vampires", "Prey",
"Fire","Shadows", "Nothing", "Deceit", "Thieves", "Isolation", "Reapers", 
"Secrets", "Sea", "Stone", "Chaos", "Gunfire", "Funk", "Salvation", "Scars", 
"Bone", "Dawn", "Water", "Glass", "Storm", "Rings", "Ash", "Circus", "Ice", 
"Lies", "Stars", "Chains", "Light", "Monster","Ivory",];