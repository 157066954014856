<template>
  <div class="home">
    <BookTitle/>
  </div>
</template>

<script>
// @ is an alias to /src
import BookTitle from "@/components/BookTitle.vue";

export default {
  name: "title",
  components: {
    BookTitle,
  },
};
</script>
